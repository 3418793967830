import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import IosArrowDropright from 'react-ionicons/lib/IosArrowDropright'
import IosBookmarkOutline from 'react-ionicons/lib/IosBookmarkOutline'
import Layout from '../components/Layout'

export const IndexPageTemplate = ({ image, title, description }) => (
  <section className="section">
    <div className="container">
      <div className="section is-main">
        <figure className="title image is-128x128">
          <img
            className="is-rounded"
            src={
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            }
            alt="Daniel Pekevski"
          />
        </figure>

        <div className="columns is-mobile is-multiline">
          <div className="column is-12">
            <h1 className="title is-3-mobile">Hello.</h1>
          </div>

          <div className="column is-12">
            <h1 className="title is-5-mobile">{title}</h1>
          </div>

          <div className="column is-6-tablet is-12-mobile">
            <h2 className="subtitle is-size-7-mobile">{description}</h2>
          </div>
        </div>

        <div className="buttons">
          <Link className="button is-black is-rounded" to="/day">
            <span>Resume</span>
            <span className="icon is-medium">
              <IosArrowDropright />
            </span>
          </Link>

          <Link className="button is-white is-rounded" to="/blog">
            <span>Read</span>
            <span className="icon is-medium">
              <IosBookmarkOutline />
            </span>
          </Link>
        </div>
      </div>
    </div>
  </section>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 128, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`
